var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { icon: "mdi-vector-polyline", title: "Equivalências" },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return [
              _c("emc-excel-import-icon", {
                attrs: { param: "equivalences" },
                on: {
                  onCompleted: function($event) {
                    return _vm.getData()
                  }
                }
              }),
              _c("emc-button-icon", {
                attrs: {
                  icon: "mdi-microsoft-excel",
                  text: "Baixar relatório",
                  color: "success darken-1"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.download(41)
                  }
                }
              }),
              _c("emc-button-icon", {
                attrs: {
                  icon: "mdi-plus",
                  text: "Novo Registro",
                  color: "indigo darken-1"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    _vm.showForm = true
                  }
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("emc-table-items", {
        attrs: {
          headers: _vm.headers,
          params: _vm.params,
          collections: _vm.collections,
          meta: _vm.meta,
          itemDelete: _vm.itemDelete,
          itemEdit: _vm.model,
          loading: _vm.loading,
          label:
            "Pesquisar disciplina, código da disciplina ou código da equivalência..."
        },
        on: {
          "update:params": function($event) {
            _vm.params = $event
          },
          "update:collections": function($event) {
            _vm.collections = $event
          },
          "update:meta": function($event) {
            _vm.meta = $event
          },
          "update:itemDelete": function($event) {
            _vm.itemDelete = $event
          },
          "update:item-delete": function($event) {
            _vm.itemDelete = $event
          },
          "update:itemEdit": function($event) {
            _vm.model = $event
          },
          "update:item-edit": function($event) {
            _vm.model = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.id",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(item.id) + " "),
                item.code
                  ? _c("small", [
                      _c("br"),
                      _vm._v("[Código: " + _vm._s(item.code) + "]")
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "item.discipline_code",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "ul",
                  { attrs: { type: "none" } },
                  _vm._l(item.equivalenceDisciplines, function(equivalence, i) {
                    return _c("li", { key: i }, [
                      _vm._v(" " + _vm._s(equivalence.discipline.code) + " ")
                    ])
                  }),
                  0
                )
              ]
            }
          },
          {
            key: "item.disciplines",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "ul",
                  { attrs: { type: "none" } },
                  _vm._l(item.equivalenceDisciplines, function(equivalence, i) {
                    return _c("li", { key: i }, [
                      _vm._v(" " + _vm._s(equivalence.discipline.name) + " ")
                    ])
                  }),
                  0
                )
              ]
            }
          },
          {
            key: "item.workloads",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "ul",
                  { attrs: { type: "none" } },
                  _vm._l(item.equivalenceDisciplines, function(equivalence, i) {
                    return _c("li", { key: i }, [
                      _vm._v(
                        " " + _vm._s(equivalence.discipline.work_load) + " "
                      )
                    ])
                  }),
                  0
                )
              ]
            }
          }
        ])
      }),
      _c("sys-register-equivalence", {
        attrs: {
          title: _vm.titleForm,
          model: _vm.model,
          show: _vm.showForm,
          maxWidth: "70%"
        },
        on: {
          onCompleted: function($event) {
            return _vm.getData()
          },
          "update:show": function($event) {
            _vm.showForm = $event
          }
        }
      }),
      _c("emc-alert-modal", {
        attrs: { text: _vm.text, disabled: _vm.deleting, show: _vm.show },
        on: {
          "update:show": function($event) {
            _vm.show = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "error",
                      loading: _vm.deleting,
                      disabled: _vm.deleting
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteItem()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-snackbar", {
        attrs: {
          show: !!_vm.message.length,
          message: _vm.message,
          color: _vm.error ? "error" : "success"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }